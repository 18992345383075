import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../Utils/PrivateRoute";
import Navigation from "./Navigation.js";
import Login from "../login/Login";
import Register from "../register/Register";
import Home from "../Home/Home";
import ConfirmAccount from "../register/ConfirmAccount";
import JobOpenings from "../JobOpenings/JobOpenings";
import Candidates from "../Candidates/Candidates";
import Assessments from "../Assessments/Assessments";
import ManageHR from "../ManageHR/ManageHR";
import AccountSettings from "../profiles/AccountSettings";
import Interviews from "../Interviews/Interviews";
import RecordedInterview from "../RecordedInterview/RecordedInterview";
import UserProfile from "../profiles/UserProfile";
import JobInfo from "../JobOpenings/JobInfo";
import Footer from "./Footer.js";
import ScrollToTop from "react-scroll-up";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import CookieDeclaration from "../Utils/CookieDeclaration";


class Content extends React.Component {
    render() {
        return (
            <div className="content">
                <div className="nav-and-switch">
                            <Navigation />
                            <Switch>
                                <Route path={"/"} exact component={() => <Home />} />
                                <Route path={"/login"} component={() => <Login />} />
                                <Route path={"/register"} component={() => <Register />} />
                                <Route path={"/home"} component={() => <Home />} />
                                <Route path={"/jobOpenings"} component={() => <JobOpenings />} />
                                <Route path={"/confirmAccount"} component={() => <ConfirmAccount />} />
                                <Route path={"/resetPassword"} component={() => <AccountSettings forgotPassword={true} />} />
                                <Route path={"/cookieDeclaration"} component={() => <CookieDeclaration />} />
                                <PrivateRoute path={"/profile"} roles={["CANDIDATE_ROLE"]} component={() => <UserProfile />} />
                                <PrivateRoute path={"/takeRecordedInterview"} roles={["CANDIDATE_ROLE"]} component={() => <RecordedInterview respond={true} />} />

                                <PrivateRoute path={"/interviews"} roles={["CANDIDATE_ROLE", "HR_ROLE", "COMPANY_ROLE"]} component={() => <Interviews />} />
                                <PrivateRoute path={"/viewRecordedInterview"} roles={["CANDIDATE_ROLE", "HR_ROLE", "COMPANY_ROLE"]} component={() => <RecordedInterview />} />
                                <PrivateRoute path={"/changePassword"} roles={["CANDIDATE_ROLE", "HR_ROLE", "COMPANY_ROLE"]} component={() => <AccountSettings />} />


                                <PrivateRoute path={"/candidates"} roles={["HR_ROLE", "COMPANY_ROLE"]} component={() => <Candidates />} />
                                <PrivateRoute path={"/assessments"} roles={["HR_ROLE", "COMPANY_ROLE"]} component={() => <Assessments />} />
                                <PrivateRoute path={"/jobOpening"} roles={["HR_ROLE", "COMPANY_ROLE"]} component={() => <JobInfo />} />

                                <PrivateRoute path={"/manageHRs"} roles={["COMPANY_ROLE"]} component={() => <ManageHR />} />
                            </Switch>
                </div>
                <ScrollToTop showUnder={0} duration={1000} >
                    <span className="fa-2x icon-color" > <FontAwesomeIcon icon={faArrowCircleUp} />  </span>
                </ScrollToTop>
                <Footer />
            </div>
        );
    }
}

export default Content;
