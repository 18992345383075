import React from "react";
import axios from "axios";
import { Col, Form, Button, Card } from "react-bootstrap";
import { AppContext } from "../Utils/AppContext";
import DateTime from "react-datetime";
import Toggle from "react-toggle";
import {URLS} from "../Utils/Constants";
import "react-datetime/css/react-datetime.css";
import "react-toggle/style.css";
import "./Interviews.css";
import { EventEmitter } from "../Utils/EventEmitter";
import LoadingButton from "../Utils/LoadingButton";

class LiveInterviewPlanner extends React.Component {
    constructor(props){
        super(props);
        this.state = this.getInitState();
    }

    getInitState(){
        return {
            participants: [{
                first_name: this.props.interviewData.candidateFirstName,
                last_name: this.props.interviewData.candidateLastName,
                email: this.props.interviewData.candidateEmail,
                is_enabled: true,
                role: "panelist"
            }],
            title: "",
            duration: 15,
            date: "",
            description: "",
            formValidated: false,
            isLoadingLiveInterview: false
        };
    }

    componentDidMount() {
        if (this.props.participants && this.props.participants !== this.state.participants) {
            this.setState({ participants: this.props.participants });
        }
    }

    addParticipant = () => {
        this.setState(prevState => { return {
            participants: [...prevState.participants, {
                first_name: "",
                last_name: "",
                email: "",
                is_enabled: false,
                role: "panelist"
            }]
        }});
    };

    deleteParticipant = (e) => {
        let target = e.target
        this.setState(prevState => {
            let participants = JSON.parse(JSON.stringify(prevState.participants));
            participants.splice(target.dataset.id, 1);
            return { participants: participants };
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        
        if (e.target.checkValidity()){
            this.planInterview();
            this.setState({ formValidated: false });
        } else {
            this.setState({ formValidated: true });
            EventEmitter.emit("showNotification", "warning", "The entered data seems to be incorrect or incomplete.");
        }
    };

    handleChange = (e) => {
        if (["first_name", "last_name", "email", "is_enabled"].includes(e.target.name)) {
            let value = (e.target.name === "is_enabled") ? e.target.checked : e.target.value;
            let participants = JSON.parse(JSON.stringify(this.state.participants));
            participants[e.target.dataset.id][e.target.name] = value;
            this.setState({ participants: participants });
        } else if(e.target.name) {
            this.setState({ [e.target.name]: e.target.value });
        }
    };

    handleDateChange = (e) => {
        if (e.toDate){
            let now = new Date();
            if (e.isSame(now, "day") && e.isBefore(now, "minute")){
                e.hour(now.getHours());
                e.minutes(now.getMinutes() + 1);
            }
            this.setState({date: e})
        }
    };

    dateIsValid = (current) => {
        let now = new Date();
        return current.isAfter(now, "day") || current.isSame(now, "day");
    };

    getTimeConstraints = () => {
        let now = new Date();
        if (!this.state.date || this.state.date.isAfter(now, "day")){
            return {
                hours: {}, minutes: {}
            }
        } else {
            return {
                hours: { min: now.getHours() },
                minutes: { min: now.getMinutes() + 1 }
            };
        }
    };

    planInterview = async () => {
        this.setState({isLoadingLiveInterview: true});
        let date = this.state.date;
        const plannerData = {
            "job_id": this.props.interviewData.jobId,
            "candidate_id": this.props.interviewData.candidateId ,
            "name": this.state.title,
            "duration": this.state.duration,
            "start_date": date.minutes(date.minutes() + new Date().getTimezoneOffset()),
            "description": this.state.description,
            "participants": this.state.participants,
            type: "live"
        };
        try {
            let response = await axios.post( `${URLS.JOBS}/interview/liveInterview`, plannerData, {
                headers: { "Authorization": `Bearer${this.context.token}` }
            });
            if (response.status === 201) {
                this.props.closeModal();
                this.setState(this.getInitState());
                EventEmitter.emit("showNotification", "success", "The selected candidate has been successfully invited to a live interview.");
                this.setState({isLoadingLiveInterview: false});
                EventEmitter.emit("getCandidates");
            }
        } catch (error) {
            EventEmitter.emit("showNotification", "danger", "Failed to create live interview.");
            console.log("ERROR IN PLANNING INTERVIEW", error);
            this.setState({isLoadingLiveInterview: false});
        }
    }

    render() {
        let participantsList = [];
        this.state.participants.forEach((participant, index) => {
            participantsList.push((
                <Card className="simple-card mb-3" key={index}>
                    <Card.Body>
                        <Form.Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>First name</Form.Label>
                                <Form.Control type="text" name="first_name" data-id={index} value={participant.first_name} onChange={this.handleChange} required pattern=".{2,}"/>
                                <Form.Control.Feedback type="invalid">Please enter a name longer than 2 characters.</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Last name</Form.Label>
                                <Form.Control type="text" name="last_name" data-id={index} value={participant.last_name} onChange={this.handleChange} required pattern=".{2,}"/>
                                <Form.Control.Feedback type="invalid">Please enter a name longer than 2 characters.</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" name="email" data-id={index} value={participant.email} onChange={this.handleChange} required/>
                                <Form.Control.Feedback type="invalid">Please enter an email address.</Form.Control.Feedback>
                            </Form.Group>
                            <Col md="6" className="d-flex align-items-center justify-content-between align-self-start" style={{paddingTop: "2rem"}}>
                                <div className="d-flex align-items-center">
                                    <div className="mr-2">Active</div>
                                    <Toggle checked={participant.is_enabled} onChange={this.handleChange} name="is_enabled" data-id={index}/>
                                </div>
                                <Button variant="danger" onClick={this.deleteParticipant} data-id={index}>Delete</Button>
                            </Col>
                        </Form.Row>
                    </Card.Body>
                </Card>
            ));
        });

        let addParticipantBtn = (participantsList.length < 4) ? <Button variant="outline-primary" onClick={this.addParticipant}>Add participant</Button> : null;

        return (
            <Form onSubmit={this.handleSubmit} noValidate validated={this.state.formValidated}>
                <Form.Row>
                    <Form.Group as={Col} md="5">
                        <Form.Label htmlFor="title">Title</Form.Label>
                        <Form.Control type="text" name="title" onChange={this.handleChange} required pattern=".{2,}"/>
                        <Form.Control.Feedback type="invalid">Please enter a title longer than 2 characters.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3">
                        <Form.Label htmlFor="duration">Duration</Form.Label>
                        <Form.Control type="number" min="15" max="120" name="duration" onChange={this.handleChange} required/>
                        <Form.Control.Feedback type="invalid">Please enter a duration between 15 and 120 minutes.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                        <Form.Label htmlFor="date">Start Date</Form.Label>
                        <div className="d-flex">
                            <DateTime  className="mr-3" timeFormat={ false } onChange={this.handleDateChange} inputProps={{required: true, placeholder: "Date"}} value={this.state.date} isValidDate={this.dateIsValid} timeConstraints={this.getTimeConstraints()}/>
                            <DateTime  dateFormat={ false } onChange={this.handleDateChange} inputProps={{required: true, placeholder: "Time"}} value={this.state.date} isValidDate={this.dateIsValid} timeConstraints={this.getTimeConstraints()}/>
                        </div>
                    </Form.Group>
                </Form.Row>
                <Form.Group>
                    <div className="d-flex justify-content-between">
                        <Form.Label>Description</Form.Label>
                        <Form.Text className="text-sec2">Optional</Form.Text>
                    </div>
                    <Form.Control as="textarea" type="text" name="description" onChange={this.handleChange}/>
                </Form.Group>

                <div hidden={!participantsList}>
                    <div className="mb-2">Participants</div>
                    { participantsList }
                </div>

                <div className="d-flex flex-row-reverse justify-content-between">
                    <LoadingButton variant={"primary"} type={"submit"} text={"Invite"} isLoading={this.state.isLoadingLiveInterview}></LoadingButton>
                    { addParticipantBtn }
                </div>
            </Form>
        );
    }
}

LiveInterviewPlanner.contextType = AppContext;
export default LiveInterviewPlanner;
