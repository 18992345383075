import React from 'react';
import axios from "axios";
import {Button, Col, Row, Card} from "react-bootstrap";
import {URLS} from "../Utils/Constants";
import {EventEmitter} from '../Utils/EventEmitter';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { LinkContainer } from "react-router-bootstrap";
import LoadingButton from "../Utils/LoadingButton";

class Register extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            role: "CANDIDATE_ROLE",
            dependency: "Individual",
            companyIdentifier: "",
            isLoadingRegister: false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    handleReset = async() =>{
        this.setState({dependency: 'Individual', role: 'CANDIDATE_ROLE'});
    }

    handleRegister = async (fields) => {
        //e.preventDefault();
        this.setState({isLoadingRegister: true});
        try {
            const credentials = {
                email: fields.email,
                password: fields.password,
                name: fields.name,
                confirmPassword: fields.confirmPassword,
                address: fields.address,
                role: fields.role,
                companyIdentifier: fields.companyIdentifier
            }

            console.log("FIELDS: ", credentials);
            console.log("DEPENDENCY: ", fields.dependency);

            let response = await axios.post(`${URLS.ACCOUNTS}/users?domain=${window.location.origin}`, credentials);
            if (response.status === 201) {
                this.setState({isLoadingRegister: false});
                EventEmitter.emit("showNotification", "success", "Your account has been successfully created. Please check your email and validate it.");
            }
        } catch (error) {
            this.setState({isLoadingRegister: false});
            EventEmitter.emit("showNotification", "danger", error.response.data.message);
        }
    }


    render() {

        return (
            <React.Fragment>
                <div className="bg-container"></div>
                <div className="container page-container">
                    <Row className="justify-content-center">
                        <Col xl={5} lg={8} md={10} xs={11}>
                            <Card className="simple-card transp-card">
                                <Card.Body>
                                    <h1 className="large-card-title">Register</h1>
                                    <Formik
                                        initialValues={{
                                            address: '',
                                            name: '',
                                            email: '',
                                            password: '',
                                            confirmPassword: '',
                                            role: 'CANDIDATE_ROLE',
                                            dependency: '',
                                            companyIdentifier: ''
                                        }}
                                        validationSchema={Yup.object().shape({
                                            name: Yup.string()
                                                .required('Name is required'),
                                            email: Yup.string()
                                                .email('Email is invalid')
                                                .required('Email is required'),
                                            address: Yup.string()
                                                .required('Address is required'),
                                            password: Yup.string()
                                                .matches(/((?=.*[a-z])(?=.*\d)(?=.*[A-Z]).{8,20})/, "The password must contain at least 8 characters, of which one number, one uppercase and one lowercase letter.")
                                                .required('Password is required'),
                                            confirmPassword: Yup.string()
                                                .matches(/((?=.*[a-z])(?=.*\d)(?=.*[A-Z]).{8,20})/, "The password must contain at least 8 characters, of which one number, one uppercase and one lowercase letter.")
                                                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                                                .required('Confirm Password is required'),
                                            role: Yup.string()
                                                .required('An option is required'),
                                            dependency: Yup.string()
                                                //.required('Select dependency')
                                        })}
                                        onSubmit={fields => {
                                            this.handleRegister(fields);
                                        }}
                                        render={({errors, touched, fields}) => (
                                            <Form>

                                                <div className="form-group">
                                                    <label htmlFor="name">Name</label>
                                                    <Field name="name" type="text" placeholder="Name"
                                                           className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}/>
                                                    <ErrorMessage name="name" component="div"
                                                                  className="invalid-feedback"/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="email">Email</label>
                                                    <Field name="email" type="text" placeholder="Email"
                                                           className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                                                    <ErrorMessage name="email" component="div"
                                                                  className="invalid-feedback"/>
                                                </div>
                                                {this.state.dependency === "Company" && this.state.role === 'HR_ROLE' && (
                                                    <div className="form-group">
                                                        <label htmlFor="email">Email of your company</label>
                                                        <Field
                                                            type="email"
                                                            name="companyIdentifier"
                                                            placeholder="Company email"
                                                            className={'form-control' + (errors.companyIdentifier && touched.companyIdentifier ? ' is-invalid' : '')}/>
                                                        <ErrorMessage name="companyIdentifier" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>
                                                )}
                                                <div className="form-group">
                                                    <label htmlFor="address">Address</label>
                                                    <Field name="address" type="text" placeholder="Address"
                                                           className={'form-control' + (errors.address && touched.address ? ' is-invalid' : '')}/>
                                                    <ErrorMessage name="address" component="div"
                                                                  className="invalid-feedback"/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="password">Password</label>
                                                    <Field name="password" type="password" placeholder="Password"
                                                           className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}/>
                                                    <ErrorMessage name="password" component="div"
                                                                  className="invalid-feedback"/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="confirmPassword">Confirm Password</label>
                                                    <Field name="confirmPassword" type="password"
                                                           placeholder="Confirm Password"
                                                           className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')}/>
                                                    <ErrorMessage name="confirmPassword" component="div"
                                                                  className="invalid-feedback"/>
                                                </div>

                                                <span className="text-muted" style={{fontSize: "80%"}}>The password must contain at least 8 characters, of which one number, one uppercase and one lowercase letter.</span>

                                                <div className="d-flex align-items-end justify-content-between">
                                                    <LinkContainer to={"/login"} className="d-block">
                                                        <a className="pl-0">Already have an account?</a>
                                                    </LinkContainer>
                                                    <div className="form-group mb-0">
                                                        <LoadingButton variant={"primary"} type={"submit"} text={"Register"} isLoading={this.state.isLoadingRegister}/>
                                                        <Button type="reset" variant="dark" className="ml-2 btn btn-secondary"  onClick={this.handleReset} >Reset</Button>
                                                    </div>
                                                </div>

                                            </Form>
                                        )}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Register;
