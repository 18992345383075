import React from "react";
import "./VideoPlayer.css";

class VideoPlayer extends React.Component {
    render() {
        return (
            <div>
                <video className="player-video" src={ this.props.videoSrc } controls></video>
            </div>
        );
    }
}

export default VideoPlayer;
