import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { AppContext } from "../Utils/AppContext";
import { URLS } from "../Utils/Constants";
import "./Interviews.css";
import InterviewNotesModal from "../RecordedInterview/InterviewNotesModal";
import { EventEmitter } from "../Utils/EventEmitter";
import InterviewCard from "../RecordedInterview/InterviewCard";
import NotificationModal from "../Utils/NotificationModal";
import InterviewInfoModal from "../RecordedInterview/InterviewInfoModal";
import records from "../Images/professions-and-jobs.svg";
import liveInterview from "../Images/live.svg";
import EmptyListInfo from "../Utils/EmptyListInfo";
import {LoadingSpinnerComponent} from "../Utils/LoadingSpinnerComponent";
import {Button, Card, Modal, Nav} from "react-bootstrap";
import Pagination from "react-js-pagination";
import Form from "react-bootstrap/Form";
import CandidateInfo from "../Candidates/CandidateInfo";
import LiveInterviewPlanner from "./LiveInterviewPlanner";
import RecordedInterviewPlanner from "./RecordedInterviewPlanner";

class Interviews extends React.Component {
    state = {
        interviews: {},
        recCandidates: {},
        liveCandidates: {},
        shouldDisplayNotesModal: false,
        displayedInterviewNotes: null,
        liveInterviews: {},
        interviewToDelete: null,
        wantToRespond: false,
        interview: {},
        isLoadingRecordedInterviews: false,
        isLoadingLiveInterviews: false,
        showRecordedInterviews: false,
        showLiveInterviews: false,
        tab: 0,
        activePageRecInterviews:  new URL(window.location.href).searchParams.get("pageRec") ? parseInt(new URL(window.location.href).searchParams.get("pageRec")):1,
        activePageLiveInterviews: 1,
        jobs : [],
        jobId : new URL(window.location.href).searchParams.get("jobRec") ? new URL(window.location.href).searchParams.get("jobRec"):"",
        interviewModalVisible: false,
        invitedCndData: {},
        interviewType: "rec"
    };

    componentDidMount() {
        this.getInterviews();
        if( this.context.role !== "CANDIDATE_ROLE")
            this.getJobs();
        // this.getLiveInterviews();
        this.showTab = this.showTab.bind(this);
    }
    showTab(tab) {
        if (tab === 1){
            this.setState({jobId:""},()=>{
                this.getLiveInterviews();
            })
        }else{
            this.setState({jobId:"",activePageRecInterviews:1},() => {
                this.props.history.push(`/interviews?pageRec=${this.state.activePageRecInterviews}&jobRec=${this.state.jobId}`);
                this.getInterviews();
            });
        }
        this.setState({ tab: tab });
    }

    handlePageChangeRec(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({activePageRecInterviews: pageNumber},()=>this.getInterviews());
        this.props.history.push(`/interviews?pageRec=${pageNumber}&jobRec=${this.state.jobId}`);

    }
    handlePageChangeLive(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({activePageLiveInterviews: pageNumber},()=>this.getLiveInterviews());
    }

    getInterviews = async () => {
        this.setState({ isLoadingRecordedInterviews: true });
        try {
            let response = await axios.get(`${URLS.JOBS}/interview?page=${this.state.activePageRecInterviews-1}&size=10&jobId=${this.state.jobId}`, {
                headers: { "Authorization": `Bearer ${this.context.token}` }
            });
            let candidatesId = [];
            if (response.status === 200) {
                let recJobInterviews = {};
                response.data.interviews.forEach((interview) => {
                    if (recJobInterviews[interview.job.id]) {
                        recJobInterviews[interview.job.id].interviews.push(interview);
                    } else {
                        recJobInterviews[interview.job.id] = {
                            postingTitle: interview.job.postingTitle,
                            companyName: interview.job.companyName,
                            interviews: [interview]
                        }
                    }
                });
                this.setState({ interviews: response.data },()=>{
                    if ( response.data.interviews.length > 0) {
                        candidatesId = this.state.interviews.interviews.map(item => item.candidateId);
                        if(candidatesId.length > 0){
                            this.getInterviewCandidates(candidatesId);
                        }
                    } else this.setState({ isLoadingRecordedInterviews: false });
                });
                this.setState({ recJobInterviews });
            } else {
                EventEmitter.emit("showNotification", "danger", "Failed to load interviews.");
                this.setState({ isLoadingRecordedInterviews: false });
            }
        } catch (error) {
            EventEmitter.emit("showNotification", "danger", "Failed to load interviews.");
            this.setState({ isLoadingRecordedInterviews: false });
        }
    };

    getLiveInterviews = async () => {
        this.setState({ isLoadingLiveInterviews: true });
        try {
            let response = await axios.get(`${URLS.JOBS}/interview/liveInterview/${this.context.userId}?page=${this.state.activePageLiveInterviews-1}&size=10&jobId=${this.state.jobId}`, {
                headers: { "Authorization": `Bearer ${this.context.token}` }
            });
            let candidatesId=[];
            if (response.status === 200) {
                let liveJobInterviews = {};
                response.data.interviews.forEach((interview) => {
                    if (liveJobInterviews[interview.job.id]) {
                        liveJobInterviews[interview.job.id].interviews.push(interview);
                    } else {
                        liveJobInterviews[interview.job.id] = {
                            postingTitle: interview.job.postingTitle,
                            companyName: interview.job.companyName,
                            interviews: [interview]
                        };
                    }
                });
                this.setState({ liveJobInterviews });
                this.setState({ liveInterviews: response.data },()=>{
                    if (response.data.interviews.length > 0) {
                        candidatesId = this.state.liveInterviews.interviews.map(item => item.candidate.id);
                        if(candidatesId.length > 0){
                            this.getLiveInterviewCandidates(candidatesId);
                        }
                    } else this.setState({ isLoadingLiveInterviews: false });
                })
            } else {
                console.log("CANNOT GET LIVE INT");
                EventEmitter.emit("showNotification", "danger", "Failed to load live interviews.");
                this.setState({ isLoadingLiveInterviews: false });
            }

        } catch (error) {
            EventEmitter.emit("showNotification", "danger", "Failed to load interviews.");
            console.log("ERROR: ", error);
            this.setState({ isLoadingLiveInterviews: false });
        }
    };

    getInterviewCandidates = async (idCandidates) => {
        try {
            let response = await axios.put(`${URLS.PROFILES}/resumes`, idCandidates, {
                headers: { "Authorization": `Bearer ${this.context.token}` }
            });
            if (response.status === 200) {
                this.setState({ candidatesRecProfiles: response.data }, ()=>{
                    this.filterRecCandidates();
                });
                this.setState({ isLoadingRecordedInterviews: false });
            } else {
                EventEmitter.emit("showNotification", "danger", "Failed to load interviews.");
                this.setState({ isLoadingRecordedInterviews: false });
            }
        } catch (error) {
            EventEmitter.emit("showNotification", "danger", "Failed to load interviews.");
            this.setState({ isLoadingRecordedInterviews: false });
        }
    };

    getLiveInterviewCandidates = async (candidatesId) => {
        try {
            let response = await axios.put(`${URLS.PROFILES}/resumes`, candidatesId, {
                headers: { "Authorization": `Bearer ${this.context.token}` }
            });
            if (response.status === 200) {
                this.setState({candidatesLiveProfiles : response.data}, ()=>{
                    this.filterLiveCandidates();
                });
                this.setState({ isLoadingLiveInterviews: false });
            } else {
                EventEmitter.emit("showNotification", "danger", "Failed to load interviews.");
                this.setState({ isLoadingLiveInterviews: false });
            }
        } catch (error) {
            EventEmitter.emit("showNotification", "danger", "Failed to load interviews.");
            this.setState({ isLoadingLiveInterviews: false });
        }
    };

    getJobs = async () => {
        try {
            let response = await axios.get(`${ URLS.JOBS }/jobs`, {
                headers: { "Authorization": `Bearer ${ this.context.token }` }
            });
            if (response.status === 200) {
                this.setState({ jobs: response.data } );
            } else {
                EventEmitter.emit("showNotification", "danger", "Failed to get jobs.");
            }
        } catch (error) {
            EventEmitter.emit("showNotification", "danger", "Failed to get jobs.");
        }
    };

    filterLiveCandidates = () =>{
        let liveCandidates = {};
        this.state.candidatesLiveProfiles.resumes.forEach( candidateProfile => {
            if (!liveCandidates[candidateProfile.accountIdentifier]){
                liveCandidates[candidateProfile.accountIdentifier] = candidateProfile;
                this.setState({liveCandidates: liveCandidates});
            }
        })
    }

    filterRecCandidates = () =>{
        let recCandidates = {};
        this.state.candidatesRecProfiles.resumes.forEach( candidateProfile => {
            if (!recCandidates[candidateProfile.accountIdentifier]){
                recCandidates[candidateProfile.accountIdentifier] = candidateProfile;
                this.setState({recCandidates: recCandidates});
            }
        })
    };

    deleteInterview = async () => {
        try {
            let response = await axios.delete(`${URLS.JOBS}/interview/${this.state.interviewToDelete.id}`, {
                headers: { "Authorization": `Bearer ${this.context.token}` },
                data: { email: this.state.recCandidates[this.state.interviewToDelete.candidateId].personalInformation.email }
            });
            if (response.status === 204) {
                this.showDeleteInterviewModal(null);
                this.getInterviews();
                EventEmitter.emit("showNotification", "success", "Interview deleted successfully!");
                console.log("DELETED INTERVIEW: ", this.state.interviewToDelete);
            }
        } catch (error) {
            console.log("DELETE INTERVIEW ERR: ", error);
            this.showDeleteInterviewModal(null);
            this.setState({ authError: `Fetch error ${error}` });
            EventEmitter.emit("showNotification", "danger", "Failed to delete interview.");
        }
    };

    viewInterview = (interview) => {
        this.props.history.push(`/viewRecordedInterview?id=${interview.id}`);
    };

    respondToInterview = (interview) => {
        this.props.history.push(`/takeRecordedInterview?id=${interview.id}&crtAsmInd=0&crtQnInd=0`);
    };

    showNotesModal = (status, displayedNotes = "") => {
        this.setState({
            displayedInterviewNotes: displayedNotes,
            shouldDisplayNotesModal: status
        });
    };

    showDeleteInterviewModal = (interview) => {
        this.setState({ interviewToDelete: interview });
    };

    displayModal = (value, interview) => {
        this.setState({
            wantToRespond: value,
            interview: interview
        })
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = (e) =>{
        e.preventDefault();
        if (this.state.tab === 0){
            this.handlePageChangeRec(1);
        }
        else {
            this.handlePageChangeLive(1);
        }
    };

    showCreateInterview = (candidateId, jobId) => {
        let candidate = null;
        if (this.state.tab === 0) {
            candidate = this.state.recCandidates[candidateId];
        }else{
            candidate = this.state.liveCandidates[candidateId];
        }
        this.setState({
            interviewModalVisible: true,
            invitedCndData: {
                jobId: jobId,
                candidateId: candidate.accountIdentifier,
                candidateName: `${candidate.personalInformation.firstName} ${candidate.personalInformation.lastName}`,
                candidateFirstName: candidate.personalInformation.firstName,
                candidateLastName: candidate.personalInformation.lastName,
                candidateEmail: candidate.personalInformation.email
            }
        });
    };

    closeCreateInterviewModal = () => {
        this.setState({
            interviewModalVisible: false,
            invitedCndData: {}
        });
    };

    recordedInterview = () => {
        this.setState({interviewType: "rec"});
    };

    liveInterview = () => {
        this.setState({interviewType: "live"});
    };

    render() {
        let interviewsContent;
        let liveInterviewsContent;
        let interviewsToShow;
        let isCandidate = this.context.role === "CANDIDATE_ROLE";
        if (this.state.interviews && this.state.interviews.totalNoInterviews !== 0) {
            let interviewsList = [];
            if (isCandidate) {
                console.log("CANDIDATE");
                for (let jobId in this.state.recJobInterviews) {
                    if(this.state.recJobInterviews[jobId].interviews.length>0){
                        for (let interview of this.state.recJobInterviews[jobId].interviews) {
                            interviewsList.push(
                                <InterviewCard
                                    key={interview.id}
                                    interview={interview}
                                    candidate={this.state.recCandidates[interview.candidateId]}
                                    viewInterview={this.viewInterview}
                                    respondToInterview={this.respondToInterview}
                                    displayModal={this.displayModal}
                                />
                            );
                        }}
                }
                interviewsContent = (
                    <div>
                        <div className="items-grid">{interviewsList}</div>
                        <div className="pt-3 d-flex flex-column align-items-center sdhjsd">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.activePageRecInterviews}
                                itemsCountPerPage={10}
                                totalItemsCount={this.state.interviews.totalNoInterviews}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChangeRec.bind(this)}
                            />
                        </div>
                    </div>
                );

            } else {
                for (let jobId in this.state.recJobInterviews) {
                    let jobInterviewsList = [];
                    if (this.state.recJobInterviews[jobId].interviews) {
                        for (let interview of this.state.recJobInterviews[jobId].interviews) {
                            if (this.state.recCandidates[interview.candidateId]) {
                                let cndName = this.state.recCandidates[interview.candidateId].personalInformation.firstName + " " + this.state.recCandidates[interview.candidateId].personalInformation.lastName;
                                jobInterviewsList.push(
                                    <InterviewCard
                                        key={interview.id}
                                        interview={interview}
                                        candidate={this.state.recCandidates[interview.candidateId]}
                                        cndName={cndName}
                                        viewInterview={this.viewInterview}
                                        deleteInterview={this.showDeleteInterviewModal}
                                        showNotesModal={this.showNotesModal}
                                    />
                                );
                            }
                        }
                        if (jobInterviewsList.length > 0) {
                            let interviewCount = jobInterviewsList.length;
                            interviewCount += (interviewCount === 1) ? " interview" : " interviews";
                            interviewsList.push(
                                <div>
                                    <div className="mt-3 mb-3">
                                        Interviews
                                        for <strong>{this.state.recJobInterviews[jobId].postingTitle}</strong> at <strong>{this.state.recJobInterviews[jobId].companyName}</strong>:
                                    </div>
                                    <div className="items-grid">{jobInterviewsList}</div>
                                </div>
                            );
                        }
                    }
                }
                interviewsContent = (
                    <div>
                        <div >{interviewsList}</div>
                        <div className="pt-3 d-flex flex-column align-items-center">
                            <Pagination

                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.activePageRecInterviews}
                                itemsCountPerPage={10}
                                totalItemsCount={this.state.interviews.totalNoInterviews}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChangeRec.bind(this)}
                            />
                        </div>
                    </div>
                );
            }
        } else {
            interviewsContent = <EmptyListInfo icon={records} width="9rem" text="You do not have any recorded interviews yet." />
        }
        if (this.state.liveInterviews && this.state.liveInterviews.totalNoInterviews !== 0) {
            let liveInterviewsList = []
            if (isCandidate) {
                for (let jobId in this.state.liveJobInterviews) {
                    for (let interview of this.state.liveJobInterviews[jobId].interviews) {
                        liveInterviewsList.push(
                            <InterviewCard
                                // key={interview.id}
                                interview={interview}
                                candidate={this.state.liveCandidates[interview.candidate.id]}
                                cndName={this.context.crtUserName}
                                viewInterview={this.viewInterview}
                                showNotesModal={this.showNotesModal}
                            />
                        );
                    }
                }
                liveInterviewsContent = (
                    <div>
                        <div className="items-grid">{liveInterviewsList}</div>
                        <div className="pt-3 d-flex flex-column align-items-center">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.activePageLiveInterviews}
                                itemsCountPerPage={10}
                                totalItemsCount={this.state.liveInterviews.totalNoInterviews}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChangeLive.bind(this)}
                            />
                        </div>
                    </div>
                );
            } else {
                for (let jobId in this.state.liveJobInterviews) {
                    let liveJobList = [];
                    if (this.state.liveJobInterviews[jobId].interviews) {
                        for (let interview of this.state.liveJobInterviews[jobId].interviews) {
                            if (this.state.liveCandidates[interview.candidate.id]) {
                                liveJobList.push(
                                    <InterviewCard
                                        interview={interview}
                                        candidate={this.state.liveCandidates[interview.candidate.id]}
                                        viewInterview={this.viewInterview}
                                        showNotesModal={this.showNotesModal}
                                    />
                                );
                            }
                        }
                        if (liveJobList.length > 0) {
                            let interviewCount = liveJobList.length;
                            interviewCount += (interviewCount === 1) ? " interview" : " interviews";
                            if (this.state.liveJobInterviews) {
                                liveInterviewsList.push(
                                    <>
                                        <div className="mt-3 mb-3">
                                            Interviews
                                            for <strong>{this.state.liveJobInterviews[jobId].postingTitle}</strong> at <strong>{this.state.liveJobInterviews[jobId].companyName}</strong>:
                                        </div>
                                        <div className="items-grid">{liveJobList}</div>
                                    </>
                                );
                            }
                        }
                    }
                }

                liveInterviewsContent = (
                    <div>
                        <div>{liveInterviewsList}</div>
                        <div className="pt-3 d-flex flex-column align-items-center" >
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.activePageLiveInterviews}
                                itemsCountPerPage={10}
                                totalItemsCount={this.state.liveInterviews.totalNoInterviews}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChangeLive.bind(this)}
                            />
                        </div>
                    </div>
                );
            }

        } else {
            liveInterviewsContent = <EmptyListInfo icon={liveInterview} width="9rem" text="You do not have any live interviews yet." />
        }

        interviewsToShow = this.state.tab === 0 ? interviewsContent : liveInterviewsContent;

        let jobOptions=[];
        {
            if (!isCandidate && this.state.jobs && this.state.jobs.length > 0) {
                this.state.jobs.forEach((job,index)=>{
                    jobOptions.push(<option value={job.id}>{job.postingTitle}</option>)
                })
            }
        }

        let interviewModalContent;
        if (this.state.interviewType === "live") {
            interviewModalContent = (
                <LiveInterviewPlanner
                    interviewData={this.state.invitedCndData}
                    closeModal={this.closeCreateInterviewModal}
                />
            );

        } else {
            interviewModalContent = (
                <RecordedInterviewPlanner
                    interviewData={this.state.invitedCndData}
                    closeModal={this.closeCreateInterviewModal}
                />
            );
        }

        let modalSubtitle = null;
        if (this.state.invitedCndData) {
            modalSubtitle = (
                <div className="mb-3">
                    You are about to
                    invite {this.state.invitedCndData.candidateName} ({this.state.invitedCndData.candidateEmail}) to
                    an interview.
                </div>
            );
        }
        return (
            <div className="container page-container">
                <h1 className="page-title">Interviews</h1>
                {this.state.isLoadingRecordedInterviews || this.state.isLoadingLiveInterviews ?
                    <LoadingSpinnerComponent isLoading={this.state.isLoadingRecordedInterviews || this.state.isLoadingLiveInterviews} />
                    :
                    <>
                        <div>
                            <div className='builder-tabs-wrapper mb-5'>
                                <BuilderTab onClick={() => this.showTab(0)} isActive={this.state.tab === 0} title='Recorded interviews' />
                                <BuilderTab onClick={() => this.showTab(1)} isActive={this.state.tab === 1} title='Live interviews' />
                            </div>
                            <Card className="simple-card mb-4" hidden={isCandidate} >
                                <Card.Body className="d-flex justify-content-between align-items-center py-3">
                                    <div className="d-flex">
                                        <Form className="d-flex" onChange={this.handleChange} onSubmit={this.handleSubmit}>
                                            <Form.Control className="mr-2" name="jobId" as="select"
                                                          value={ this.state.jobId }>
                                                <option value="">All interviews</option>
                                                {jobOptions}
                                            </Form.Control>
                                        </Form>
                                        <Button className="mr-2" onClick={this.handleSubmit}>Search</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        {interviewsToShow}
                    </>
                }

                <InterviewNotesModal
                    notes={this.state.displayedInterviewNotes}
                    show={this.state.shouldDisplayNotesModal}
                    onHide={() => this.showNotesModal(false)}
                />
                <NotificationModal
                    title="Delete interview"
                    content="Are you sure you want to delete the selected interview?"
                    confirmAction={this.deleteInterview}
                    show={this.state.interviewToDelete !== null}
                    onHide={() => { this.showDeleteInterviewModal(null) }}
                />
                <InterviewInfoModal
                    respondToInterview={this.respondToInterview}
                    interview={this.state.interview}
                    show={this.state.wantToRespond}
                    onHide={() => this.displayModal(false)}
                />
                <CandidateInfo
                    showCreateInterview={this.showCreateInterview}
                />
                <Modal size="lg" show={this.state.interviewModalVisible} onHide={this.closeCreateInterviewModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Invite to interview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {modalSubtitle}
                        <Nav variant="tabs" defaultActiveKey="rec">
                            <Nav.Item>
                                <Nav.Link eventKey={"rec"} onClick={this.recordedInterview}
                                          active={this.state.interviewType === "rec"}>Recorded interview</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"live"} onClick={this.liveInterview}
                                          active={this.state.interviewType === "live"}>Live interview</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <div className="interview-tab-content-wrapper">
                            {interviewModalContent}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

Interviews.contextType = AppContext;
export default withRouter(Interviews);

class BuilderTab extends React.Component {
    render() {
        let className = (this.props.isActive) ? 'builder-tab builder-tab-active' : 'builder-tab';
        return <button className={className} onClick={this.props.onClick}>{this.props.title}</button>;
    }
}
