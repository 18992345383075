export const URLS = {
    ACCOUNTS: process.env.REACT_APP_API_ACCOUNTS,
    JOBS: process.env.REACT_APP_API_JOBS,
    PROFILES: process.env.REACT_APP_API_PROFILES,
    HYPERTALK_MEETING: process.env.REACT_APP_HYPERTALK_MEETING,
    HYPERTALK_BACKEND: process.env.REACT_APP_BACKEND_HYPERTALK
};

export const COOKIES_EXP_DAYS = 30;

export const CandidateStatus = Object.freeze({
    "PENDING": "PENDING",
    "ACCEPTED": "ACCEPTED",
    "REJECTED": "REJECTED",
    "APPLIED": "APPLIED",
    "SCHEDULED": "SCHEDULED",
    "REFUSED": "REFUSED",
    "RECHECK": "RECHECK",
    "DENIED": "DENIED"
});

export const SkillCategories = Object.freeze({
    "Linguistic": "Linguistic",
    "Communication": "Communication",
    "Organisational": "Organisational",
    "JobRelated": "Job related",
    "Computer": "Digital",
    "Other": "Other"
});