import React from "react";
import {Modal, Nav} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import LiveInterviewPlanner from "../Interviews/LiveInterviewPlanner";
import RecordedInterviewPlanner from "../Interviews/RecordedInterviewPlanner";
import CandidateInfo from "./CandidateInfo";
import CandidateCard from "./CandidateCard";
import Filter from "../Utils/Filter";
import {AppContext} from "../Utils/AppContext";
import "./Candidates.css";
import {EventEmitter} from "../Utils/EventEmitter";
import axios from "axios";
import {URLS} from "../Utils/Constants";
import EmptyListInfo from "../Utils/EmptyListInfo";
import candidate from "../Images/portfolio.svg";

class CandidatesList extends React.Component {
    state = {
        interviewModalVisible: false,
        invitedCndData: {},
        interviewType: "rec",
        filterApplied: false,
        skill: "",
        candidateStatus: "",
        recommendations: {}
    };

    componentDidMount() {
        EventEmitter.on("getCandidates", this.props.getCandidates);
        this.getRecommendations();
    }

    componentWillUnmount() {
        EventEmitter.off("getCandidates");
    }

    componentDidUpdate(prevProps) {
        if ((this.props.job !== prevProps.job) && (this.context.role === "COMPANY_ROLE")) {
            this.getRecommendations();
        }
    }

    showCreateInterview = (candidateId, jobId,candidateWithJob = false) => {
        let candidate = null;
        if (candidateWithJob === true) {
            console.log("(candidate with job ) show create interview for candidate " + candidateId + " job " + jobId);
            candidate = this.props.candidates.filter(item => (item.candidate.idCandidate === candidateId && item.candidate.job.id === jobId))[0].resume;
            console.log(candidate)
            this.setState({
                interviewModalVisible: true,
                invitedCndData: {
                    jobId: jobId,
                    candidateId: candidate.accountIdentifier,
                    candidateName: `${candidate.personalInformation.firstName} ${candidate.personalInformation.lastName}`,
                    candidateFirstName: candidate.personalInformation.firstName,
                    candidateLastName: candidate.personalInformation.lastName,
                    candidateEmail: candidate.personalInformation.email
                }
            });
        } else {
            let candidate = this.props.candidates[candidateId];
            this.setState({
                interviewModalVisible: true,
                invitedCndData: {
                    jobId: jobId,
                    candidateId: candidate.accountIdentifier,
                    candidateName: `${candidate.personalInformation.firstName} ${candidate.personalInformation.lastName}`,
                    candidateFirstName: candidate.personalInformation.firstName,
                    candidateLastName: candidate.personalInformation.lastName,
                    candidateEmail: candidate.personalInformation.email
                }
            });
        }
        ;
    }
    getRecommendations = () => {
        if (this.props.job)
            this.getRecommendationForJob(this.props.job.id);
    };

    getRecommendationForJob = async (jobId) => {
        if (this.context.role === "COMPANY_ROLE") {
            try {
                let requestOptions = {};
                if (this.context.token) {
                    requestOptions.headers = {"Authorization": `Bearer ${this.context.token}`};
                }
                let response = await axios.get(`${URLS.JOBS}/recommendations/${jobId}`, requestOptions);
                if (response.status === 200) {
                    this.setState(prevState => {
                        let recommendations = JSON.parse(JSON.stringify(prevState.recommendations));
                        recommendations[jobId] = response.data;
                        return {recommendations: recommendations};
                    });
                } else {
                }
            } catch (error) {
                // EventEmitter.emit("showNotification", "danger", "Cannot get recommendations.");
            }
        }
    };

    closeCreateInterviewModal = () => {
        this.setState({
            interviewModalVisible: false,
            invitedCndData: {}
        });
    };

    recordedInterview = () => {
        this.setState({interviewType: "rec"});
    };

    liveInterview = () => {
        this.setState({interviewType: "live"});
    };
    shouldDisplayCandidate =  (jobCnd) => {
        if ((jobCnd.status === this.state.candidateStatus || this.state.candidateStatus === "") && this.state.candidates[jobCnd.candidate]) {
            if (!this.state.skill) return true;
            let candidate = this.state.candidates[jobCnd.candidate];
            if (candidate.accountIdentifier === jobCnd.candidate) {
                for (let skillCategory of Object.keys(candidate.skills)){
                    for (let skill of candidate.skills[skillCategory]) {
                        if (skill && skill.toLowerCase().includes(this.state.skill.toLowerCase())) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    };

    render() {
        let candidatesList = [];
        if (this.props.job && this.props.candidates) {
            // this.props.jobs.forEach((job, jobIndex) => {
                if (this.props.job.enabled === true && this.props.job.candidates) {
                    let jobCandidatesList = [];


                    this.props.job.candidates.forEach((jobCnd, cndIndex) => {
                        // if (this.shouldDisplayCandidate(jobCnd)) {
                            let crtCandidate = this.props.candidates[jobCnd.candidate];
                            // let isRecommended = (this.state.recommendations[job.id] && this.state.recommendations[job.id].candidates.includes(candidate.accountIdentifier))
                                if (crtCandidate) {
                                    jobCandidatesList.push(
                                        <CandidateCard
                                            shortlist={this.props.shortlist}
                                            key={cndIndex}
                                            entity="candidates"
                                            status={jobCnd.status}
                                            candidate={crtCandidate}
                                            job={this.props.job}
                                            showCreateInterview={this.showCreateInterview}
                                            recommendation={this.state.recommendations}
                                        />
                                    );
}
                        // }
                    });

                    // for(let candidate of this.props.candidates){
                    //     for(let cnd of this.props.job.candidates){
                    //         if (cnd.candidate === candidate.accountIdentifier){
                    //             jobCandidatesList.push(
                    //                     <CandidateCard
                    //                         shortlist={this.props.shortlist}
                    //
                    //                         entity="candidates"
                    //                         status={cnd.status}
                    //                         candidate={candidate}
                    //                         job={this.props.job}
                    //                         showCreateInterview={this.showCreateInterview}
                    //                         recommendation={this.state.recommendations}
                    //                     />
                    //                 );
                    //         }
                    //     }
                    // };

                    if (jobCandidatesList.length) {
                        let cndCount = jobCandidatesList.length;
                        cndCount += (cndCount === 1) ? " candidate" : " candidates";
                        candidatesList.push(
                            <div className="mb-5" >
                                <div className="mb-3">
                                    {cndCount} applied
                                    for <strong>{this.props.job.postingTitle}</strong> at <strong>{this.props.job.companyName}</strong>:
                                </div>
                                <div className="items-grid">{jobCandidatesList}</div>
                            </div>
                        );
                    }
                }
            // });
        }else if (this.props.candidates){
            let jobCandidatesList = [];
            this.props.candidates.forEach((candidate) => {
                console.log(candidate)
                    jobCandidatesList.push(
                        <CandidateCard
                            shortlist={this.props.shortlist}
                            // key={cndIndex}
                            entity="candidates"
                            status={candidate.candidate.statusCandidate}
                            candidate={candidate.resume}
                            job={candidate.candidate.job}
                            showCreateInterview={this.showCreateInterview}
                            recommendation={this.state.recommendations}
                            isCandidateWithJob={true}
                        />
                    );
                }
            );
            if (jobCandidatesList.length) {
                let cndCount = jobCandidatesList.length;
                cndCount += (cndCount === 1) ? " candidate" : " candidates";
                candidatesList.push(
                    <div className="mb-5" >
                        <div className="items-grid">{jobCandidatesList}</div>
                    </div>
                );
            }

        }

        let candidatesContent = (candidatesList.length) ? candidatesList : <EmptyListInfo icon={candidate} width="10rem" text="No candidates to show."/>;

        let interviewModalContent;
        if (this.state.interviewType === "live") {
            interviewModalContent = (
                <LiveInterviewPlanner
                    interviewData={this.state.invitedCndData}
                    closeModal={this.closeCreateInterviewModal}
                />
            );

        } else {
            interviewModalContent = (
                <RecordedInterviewPlanner
                    interviewData={this.state.invitedCndData}
                    closeModal={this.closeCreateInterviewModal}
                />
            );
        }

        let modalSubtitle = null;
        if (this.state.invitedCndData) {
            modalSubtitle = (
                <div className="mb-3">
                    You are about to
                    invite {this.state.invitedCndData.candidateName} ({this.state.invitedCndData.candidateEmail}) to
                    an interview.
                </div>
            );
        }


        let filter = null;
        // if (Object.keys(this.props.candidates).length) {
        //     filter = (
        //         <Filter
        //             entity="candidates"
        //             updateState={this.updateState}
        //             filterApplied={this.state.filterApplied}
        //             updateFilter={this.updateFilter}
        //             jobs={this.props.jobs}
        //         />
        //     )
        // }

        return (
            <div>
                {/*{filter}*/}
                {candidatesContent}

                <CandidateInfo
                    showCreateInterview={this.showCreateInterview}
                    shortlist={this.props.shortlist}
                />

                <Modal size="lg" show={this.state.interviewModalVisible} onHide={this.closeCreateInterviewModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Invite to interview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {modalSubtitle}
                        <Nav variant="tabs" defaultActiveKey="rec">
                            <Nav.Item>
                                <Nav.Link eventKey={"rec"} onClick={this.recordedInterview}
                                          active={this.state.interviewType === "rec"}>Recorded interview</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"live"} onClick={this.liveInterview}
                                          active={this.state.interviewType === "live"}>Live interview</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <div className="interview-tab-content-wrapper">
                            {interviewModalContent}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

CandidatesList.contextType = AppContext;
export default withRouter(CandidatesList);
