import React from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { AppContext } from "../Utils/AppContext";
import JobOpeningsList from "./JobOpeningsList";
import CreateJob from './CreateJob';
import NotificationModal from "../Utils/NotificationModal";
import Filter from "../Utils/Filter";
import { URLS } from "../Utils/Constants";
import { EventEmitter } from "../Utils/EventEmitter";
import "./JobOpenings.css";
import {LoadingSpinnerComponent} from "../Utils/LoadingSpinnerComponent";

class JobOpenings extends React.Component {
    state = {
        jobsList: [],
        jobToEdit: null,
        shouldCreateNewJob: true,
        selectedStatus: "",
        searchKeywords: "",
        location: "",
        keyword: "",
        selectedAppliedMode: 2,
        crtAppliedMode: 2,
        filterApplied: false,
        jobToDelete: null,
        isLoadingJobs: false
    };

    componentDidMount() {
        this.getJobs();
    }

    updateState = (updater, callback) => {
        this.setState(updater, callback);
    };

    openAddJobModal = () => {
        let logo = "";
        let companyName = "";

        if (this.context.role === "HR_ROLE") {
            if (this.context.user.companyName) companyName = this.context.user.companyName;
            if (this.context.user.companyLogo) logo = this.context.user.companyLogo;

        } else if (this.context.role === "COMPANY_ROLE") {
            if (this.context.user.companyName) companyName = this.context.user.name;
            if (this.context.user.companyLogo) logo = this.context.user.avatar;
        }

        this.setState({
            shouldCreateNewJob: true,
            jobToEdit: {
                "logo": logo,
                "companyName": companyName,
                "postingTitle": "",
                "address": "",
                "type": "",
                "salary": "",
                "workExperience": "",
                "skillset": [],
                "description": "",
                "enabled": true
            },
            searchKeywords: "",
            location: "",
            status: ""
        });
    };

    openEditJobModal = (jobToEdit) => {
        this.setState({ shouldCreateNewJob: false, jobToEdit: jobToEdit });
    };

    closeEditJobModal = () => {
        this.setState({ jobToEdit: null });
    };

    getJobs = async () => {
        this.setState({isLoadingJobs : true});
        try {
            this.setState({ crtAppliedMode: this.state.selectedAppliedMode });
            let jobFilter = `status=${ escape(this.state.selectedStatus) }&keywords=${ escape(this.state.searchKeywords) }&location=${ escape(this.state.location) }`;
            let filterApplied = (this.state.selectedStatus !== "" || this.state.searchKeywords !== "" || this.state.location !== "" || this.state.selectedAppliedMode !== 2);
            this.setState({ filterApplied: filterApplied });

            let requestOptions = {};
            if (this.context.token) {
                requestOptions.headers = { "Authorization": `Bearer ${ this.context.token }` };
            }
            let response = await axios.get(`${ URLS.JOBS }/jobs?${ jobFilter }&companyID=51`, requestOptions);
            if (response.status === 200) {
                this.setState({ jobsList: response.data });
                this.setState({isLoadingJobs : false});
            }
        } catch (error) {
            EventEmitter.emit("showNotification", "danger", "Failed load job openings.");
            this.setState({isLoadingJobs : true});
        }
    };

    deleteJob = async () => {
        try {
            let response = await axios.delete(`${ URLS.JOBS }/jobs/${ this.state.jobToDelete }`, {
                headers: { "Authorization": `Bearer ${ this.context.token }` }
            });
            if (response.status === 204) {
                this.getJobs();
                this.showDeleteJobOpeningModal(null);
                EventEmitter.emit("showNotification", "success", "Job opening successfully deleted.");
            }
        } catch (error) {
            this.showDeleteJobOpeningModal(null);
            EventEmitter.emit("showNotification", "danger", "Failed to delete job opening.");
        }
    };

    showDeleteJobOpeningModal = (jobId) => {
        this.setState({ jobToDelete: jobId });
    };

    render() {
        let isHr = this.context.role === "HR_ROLE";
        let isCompany = this.context.role === "COMPANY_ROLE";
        // let jobOpeningsList;
        //
        // if (this.state.jobsList.length > 0) {
        //     jobOpeningsList = <JobOpeningsList deleteJob={ this.showDeleteJobOpeningModal } openEditJobModal={ this.openEditJobModal } reloadJobs={ this.getJobs } jobsList={ this.state.jobsList } appliedMode={ this.state.crtAppliedMode } />;
        // } else {
        //     jobOpeningsList = <div>You do not have any job openings yet.</div>;
        // }

        let createJobBtn = null;
        if (isHr || isCompany) {
            createJobBtn = <Button onClick={ this.openAddJobModal }>Create new</Button>;
        }

        let jobOpeningsTitle = null;
        if (!this.context.isIframe) {
            jobOpeningsTitle = (
                <div className="d-flex align-items-center justify-content-between">
                    <h1 className="page-title">Job openings</h1>
                    { createJobBtn }
                </div>
            );
        }

        return (
            <div className="container page-container">
                { jobOpeningsTitle }

                <Filter entity="jobOpenings" filterApplied={ this.state.filterApplied } search={ this.getJobs } updateState={ this.updateState } />

                <CreateJob
                    jobToEdit={ this.state.jobToEdit }
                    closeEditJobModal={ this.closeEditJobModal }
                    shouldCreateNewJob={ this.state.shouldCreateNewJob }
                    getJobs={ this.getJobs }
                />

                <NotificationModal
                    title="Delete job opening"
                    content="Are you sure you want to delete the selected job opening?"
                    confirmAction={ this.deleteJob }
                    show={ this.state.jobToDelete !== null }
                    onHide={ () => { this.showDeleteJobOpeningModal(null) } }
                />
                {this.state.isLoadingJobs ? <LoadingSpinnerComponent isLoading={this.state.isLoadingJobs}/>
                : <JobOpeningsList
                    deleteJob={ this.showDeleteJobOpeningModal }
                    openEditJobModal={ this.openEditJobModal }
                    reloadJobs={ this.getJobs }
                    jobsList={ this.state.jobsList }
                    appliedMode={ this.state.crtAppliedMode }
                />}
            </div>
        );
    }
}

JobOpenings.contextType = AppContext;
export default JobOpenings;
